import * as React from "react";



import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";



import * as styles from "../styles/legal.module.scss";

export const Head = () => {
	return <HeadMeta title="Legal Information, Disclaimers and Terms of Use" />;
};

const LegalPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.legalWrap}>
				<h1>Legal</h1>
				<div role="doc-subtitle">
					Legal Information, Disclaimers and Terms of Use
				</div>
				<h2>Terms of Use and Legal Information</h2>
				<p>
					By accessing this site, you signify your agreement with and
					understanding of the following Terms of Use pertaining to both this
					site and any material contained therein. This site is offered to you
					conditioned on your acceptance without modification of the terms,
					conditions, and notices contained herein. Your use of this site
					constitutes your agreement to all such terms, conditions, and notices.
					Cynosure Management, LLC and its affiliates (collectively, “Cynosure”)
					reserves the right to change the terms, conditions, and notices under
					which this site is offered without prior notice at any time.
				</p>
				<h2>Ownership of Site and Trademarks/Lawful Use</h2>
				<p>
					Cynosure owns and maintains this site. Nothing at this site shall be
					construed as granting, by implication, estoppel or otherwise, any
					license or right to use any photograph, image, trademark, logo or
					service mark at the site. No act of downloading or otherwise copying
					from this site will transfer title to any software or material at this
					site to you. Anything that you transmit to this site becomes the
					property of Cynosure, may be used by Cynosure for any lawful purpose,
					and is further subject to disclosure as deemed appropriate by
					Cynosure, including to any legal or regulatory authority to which
					Cynosure is subject. Cynosure reserves all rights with respect to
					copyright and trademark ownership of all material at this site, and
					will enforce such rights to the full extent of the law. This site is
					for your personal and non-commercial use. You may not modify, copy,
					distribute, transmit, display, perform, reproduce, publish, license,
					create derivative works from, transfer, or sell any information,
					products, or services obtained from this site.
				</p>
				<h2>Restrictions on Use</h2>
				<p>
					While using the Site, you agree to comply with this Agreement and all
					applicable laws, rules and regulations, and will not encourage or
					promote any activity that violates this Agreement. By accessing or
					otherwise using the Site, you agree that you will not under any
					circumstances:
				</p>
				<ul>
					<li>
						Copy or print material from the Site (including but not limited to
						individual Site pages or any research or reports posted on the Site)
						for commercial purposes without our prior permission. You must also
						get our permission before making more than minimal copies of Site
						materials or copying large portions of the Site. Any use of Site
						materials must not alter the original Site content, including all
						legal notices and legends;
					</li>
					<li>Link to or frame this Site or any portion thereof;</li>
					<li>
						Link to or frame tInterfere or attempt to interfere with the Site’s
						networks or network security, or attempt to use the Site to gain
						unauthorized access to any other computer system;
					</li>
					<li>
						Make any automated use of the Site, or take any action that we deem
						to impose or to potentially impose an unreasonable or
						disproportionately large load on our serves or network
						infrastructure;
					</li>
					<li>
						Bypass any measures we take to restrict access to the Site or use
						any software, technology or device to scrape, spider, or crawl the
						Site or harvest or manipulate data; and
					</li>
					<li>Publish or link to malicious content</li>
				</ul>
				<h2>No Offer of Securities or Advice – Disclaimers</h2>
				<p>
					The information contained herein is not investment advice. You
					acknowledge that the content of the Site is for general, informational
					purposes only and is not intended to constitute an offer to sell or
					buy any securities or other assets or promise to undertake or solicit
					business, and may not be relied upon in connection with any offer or
					sale of securities or other assets.
				</p>
				<p>
					Offers can only be made where lawful under, and in compliance with,
					applicable law. We make no representations that any information
					provided via the Site is accurate, current, reliable or complete. You
					are solely responsible for evaluating the risks and merits regarding
					the use of the Site and any services provided herein. We are not
					utilizing the Site to provide investment, legal, accounting, tax or
					other professional advice, and nothing on the Site is to be deemed a
					recommendation that you buy, sell or hold any security or other
					investment or that you pursue any investment style or strategy. If you
					would like investment, accounting, tax or legal advice, you should
					consult with your own advisors with respect to your individual
					circumstances and needs.
				</p>
				<p>
					Any specific company listed or discussed is not intended to be
					representative of any or all companies purchased, sold or recommended
					for Cynosure’s advisory clients. One should not assume that all
					investments in the companies identified are or will be profitable.
				</p>
				<p>
					Access to information about any accounts is limited to investors who,
					among other requirements, either qualify as accredited investors
					within the meaning of the Securities Act of 1933, as amended, or those
					investors who generally are sophisticated in financial matters, such
					that they are capable of evaluating the merits and risks of
					prospective investments.
				</p>
				<p>
					The quotes provided by personnel of portfolio companies of certain of
					Cynosure’s advisory clients may not be representative of the
					experience of personnel of other portfolio companies, and are not a
					guarantee of future performance or success.
				</p>
				<h2>Forward-Looking Statements and Past Performance</h2>
				<p>
					The contents of the Site may contain forward-looking statements that
					are based on beliefs, assumptions, current expectations, estimates,
					and projections about the financial industry, the economy, Cynosure
					itself or its investments. These statements do not guarantee future
					performance and involve certain risks, uncertainties and assumptions
					that are difficult to predict with regard to timing, extent,
					likelihood and degree of occurrence. Therefore, actual results and
					outcomes may materially differ from what may be expressed or
					forecasted in such forward-looking statements. Furthermore, Cynosure
					undertakes no obligation to update, amend or clarify forward-looking
					statements, whether as a result of new information, future events or
					otherwise. Furthermore, past performance is not indicative of future
					results; no representation is being made that any investment will or
					is likely to achieve profits or losses similar to those achieved in
					the past, or that significant losses will be avoided.
				</p>
				<h2>No Reliance</h2>
				<p>
					While Cynosure uses reasonable efforts to update the information on
					this site, Cynosure makes no representations or warranties as to the
					accuracy, reliability or completeness of any information at this site.
					Opinions, advice and any other content at this website, including the
					terms and conditions of your use of this service, are subject to
					change without notice or liability.
				</p>
				<h2>Material to be Consulted in its Entirety</h2>
				<p>
					All materials at this site are meant to be reviewed in their entirety,
					including any footnotes, legal disclaimers, restrictions or
					disclosures, and any copyright or proprietary notices. Any
					disclaimers, restrictions, disclosures or clauses apply to any partial
					document or material in the same manner as they do the whole, and will
					be deemed incorporated in the portion of any material or document that
					you consult or download.
				</p>
				<h2>Third Party Content</h2>
				<p>
					Some of the content on the Site is produced by users, publishers, and
					other third parties and is not controlled by Cynosure. You acknowledge
					that such content is the sole responsibility of the third-party person
					or organization that created it and we assume no responsibility for
					any third-party content or information. Although Cynosure reserves the
					right to review all content and information that appears on the Site
					and to remove any content or information at Cynosure’s discretion, we
					do not necessarily review all or any of it. Access and use of
					third-party content made available through the Site is solely at your
					own risk and Cynosure makes no warranties, express, statutory, or
					implied, with respect to such third-party content or information. This
					Agreement does not govern your use of any site or service other than
					the Site. You should review applicable terms and policies of any
					linked third-party content, information or websites. To the maximum
					extent permitted by applicable law, we will not be liable for any
					content submitted, posted, linked to, shared or otherwise made
					available via the Site, including, but not limited to, any
					inaccuracies, errors or omissions in any content, any intellectual
					property infringement with relation to the linked content, or any loss
					or damage of any kind incurred as a result of the use of any content
					linked in or otherwise made available via the Site.
				</p>
				<h2>Cookies</h2>
				<p>
					This website may utilize “cookies.” A cookie is an element of data
					that a website can send to your browser and store on your computer’s
					hard drive. They are used by Cynosure to determine user activity
					throughout its website (such as specific pages visited, time spent on
					those pages, and frequency of visits). Cynosure uses this information
					to analyze usage patterns and, more importantly, to provide you with a
					more rewarding experience each time you visit the Cynosure website.
					Cynosure does not use cookies to store or transmit any personally
					identifiable information about you on the Internet.
				</p>
				<h2>Statement Regarding Internet Phishing and Fraud</h2>
				<p>
					Please be aware that bad actors may attempt to misuse Cynosure’s name,
					brand and reputation by publishing fake websites and engaging in
					phishing scams seeking personal or confidential information. Cynosure
					does not authorize such misuse of its name, brand and reputation and
					investigates these matters to ensure that its legal rights and
					reputation are protected.
				</p>
				<p>
					When you communicate with Cynosure through a digital media platform,
					please remember the following, especially before disclosing personal
					information:
				</p>
				<ul>
					<li>
						Confirm that you are visiting a website authorized by Cynosure.{" "}
					</li>
					<li>
						Communicate only with individuals who are affiliated with an
						authorized office of Cynosure.{" "}
					</li>
					<li>
						Employees and authorized representatives of Cynosure are only
						permitted to use an authorized email address of Cynosure to conduct
						business on behalf of Cynosure. Authorized email addresses of The
						Cynosure use “@cynosuregroup.com.” Do not send emails to anyone
						claiming to be an employee or authorized representative of Cynosure
						if they do not use an authorized email address of Cynosure.
					</li>
					<li>
						Do not share your password or login information with anyone,
						including anyone from Cynosure. Aside from the operation of certain
						private websites that are only available to investors through a
						secure login procedure, Cynosure will never ask you for your
						password or login information.
					</li>
				</ul>
				<h2>No Warranty</h2>
				<p>
					The materials in this site are provided “as is” without warranty of
					any kind, either express or implied, to the fullest extent permissible
					pursuant to applicable law. Cynosure further assumes no responsibility
					for, and makes no warranties that, functions contained at this site
					will be uninterrupted or error-free, that defects will be corrected,
					or that the site or the server that makes it available will be free of
					viruses or other harmful components. Cynosure shall not be liable for
					any damages to, viruses that may infect, or services, repairs or
					corrections that must be performed, on your computer or other property
					on account of your accessing or use of this site. Please note that
					some jurisdictions do not allow the exclusion of implied warranties,
					so some or all of the above exclusions may not apply to you.
				</p>
				<h2>Limitation of Liability</h2>
				<p>
					IN NO EVENT SHALL CYNOSURE BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT
					OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING
					BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFIT, OR LOSS OF DATA,
					WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
					NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
					THE USE OF THIS WEBSITE OR THE MATERIAL CONTAINED IN, OR ACCESSED
					THROUGH, THIS WEBSITE. IN NO EVENT SHALL CYNOSURE TOTAL LIABILITY FOR
					ANY AND ALL DAMAGES AND CAUSES OF ACTION EXCEED THE AMOUNT PAID BY
					YOU, IF ANY, FOR THE USE OF THIS WEBSITE.
				</p>
				<p>
					Certain laws do not allow limitations or implied warranties, or the
					exclusion or limitation of certain damages. If these laws apply, some
					or all of the above disclaimers, exclusions, or limitations, may not
					apply to you, and you may have additional rights to those contained
					herein.
				</p>
				<h2>Severability</h2>
				<p>
					In the event any of the terms or provisions of these terms and
					conditions shall be held to be unenforceable, the remaining terms and
					provisions shall be unimpaired and the unenforceable term or provision
					shall be replaced by such enforceable term or provision as comes
					closest to the intention underlying the unenforceable term or
					provision. These terms and conditions shall be subject to any other
					agreements you have entered into with Cynosure.
				</p>
				<h2>Disputes</h2>
				<p>
					All claims, disputes or disagreements which may arise out of the
					interpretation, performance or in any way relating to your use of this
					site and any and all other Cynosure site(s), shall be submitted
					exclusively to the jurisdiction of the State or federal courts located
					in the State of Utah and the County of Salt Lake. In the event any
					portion of these Terms of Use is found to be invalid or unenforceable
					for any reason, such invalidity or unenforceability shall not affect
					the enforceability or validity of any other portion of these Terms of
					Use and, which shall remain in full force and effect and be construed
					as if the invalid or unenforceable portion were not part of these
					Terms of Use. You agree that your breach of these Terms of Use may
					result in irreparable harm to Cynosure which cannot be compensated by
					money damages, and that Cynosure shall be entitled to obtain
					injunctive relief for any such breach.
				</p>
				<h2>Global Dealing Restrictions</h2>
				<p>
					The materials and services at this website are intended for U.S.
					persons only. Cynosure makes no representation that this website is
					appropriate for use in all locations, or that transactions,
					securities, products, instruments or services discussed at this
					website are available or appropriate for sale or use in all
					jurisdictions, or by all investors or counter parties. Those who
					access this website are responsible for compliance with applicable
					local laws or regulations. No software at this website may be
					downloaded or otherwise exported in contravention of U.S. Department
					of Treasury or U.S. Department of Commerce regulation.
				</p>
				<h2>Applicable Law</h2>
				<p>
					These terms and conditions shall be governed by and construed in
					accordance with the laws of the State of Utah, applicable to
					agreements made and entirely to be performed within the State of Utah
					without resort to its conflict of law provisions.
				</p>

				<time>This Legal page was last updated on June 5, 2020.</time>
			</div>
		</Frame>
	);
};

export default LegalPage;
